import React, { useEffect, useState, createContext } from "react";
import css from "./styles/Menu.module.scss";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ConfettiExplosion from "react-confetti-explosion";
import "react-toastify/dist/ReactToastify.css";
import Container from "./components/Container";
import Signup from "./components/Login/Signup";
import Signin from "./components/Login/Signin";
import IncreaseChance from "./components/OtherPages/IncreaseChance";
import Help from "./components/OtherPages/Help";
import HowToPlay from "./components/OtherPages/HowToPlay";
import History from "./components/OtherPages/History";
import PredictionLimit from "./components/OtherPages/PredictionLimit";
import FramePlay from "./components/OtherPages/FramePlay";
import FrameDeposit from "./components/OtherPages/FrameDeposit";

export const Context = createContext();

const App = () => {
	const [lang, setLang] = useState(false);
	const [path, setPath] = useState("signin");
	const [data, setData] = useState("");
	const [leadData, setLeadData] = useState({});
	const [limit, setLimit] = useState({});
	const [percent, setPercent] = useState({});

	const [menu, setMenu] = useState(1);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		document.fonts.ready
			.then(() => {
				setIsLoading(false);
			})
			.catch(error => {
				console.error("Сталася помилка під час завантаження шрифтів:", error);
			});
	}, []);

	useEffect(() => {
		const storeId = JSON.parse(localStorage.getItem("aviatorUser"));
		if (!!storeId) {
			setData(storeId);
			setLeadData(storeId);
			setPath("");
		}
	}, []);

	return (
		<>
			{isLoading && (
				<div className="uiLoaderContainer">
					<span className="loader"></span>
				</div>
			)}
			<>
				<Context.Provider value={{ language: lang }}>
					<section className={classNames(css.head, "head")}>
						<div className={css.logo} />
						<div
							className={css.languages}
							onClick={() => setLang(!lang)}
						>
							<div className={css.languageContainer}>
								<div className={classNames(css.language, !lang && css._active)}>ENG</div>
								<div className={classNames(css.language, lang && css._active)}>HIN</div>
							</div>
						</div>
					</section>
					<FrameDeposit
						menu={menu}
						setMenu={setMenu}
						leadData={Object.keys(leadData).length > 0 ? leadData : data}
					/>
					{(path === "" || !path) && (
						<>
							<FramePlay
								menu={menu}
								setMenu={setMenu}
								leadData={leadData}
							/>
							<IncreaseChance
								menu={menu}
								setMenu={setMenu}
								leadData={leadData}
							/>
							<Help
								menu={menu}
								setMenu={setMenu}
								leadData={leadData}
							/>
							<HowToPlay
								menu={menu}
								setMenu={setMenu}
								leadData={leadData}
							/>
							<History
								menu={menu}
								setMenu={setMenu}
								leadData={leadData}
							/>
							<PredictionLimit
								menu={menu}
								setMenu={setMenu}
								limit={limit}
								percent={percent}
								leadData={leadData}
							/>
							<div className="uiContainer">
								<div className="uiBgElement" />
								<Container
									data={data}
									setMenu={setMenu}
									setLimit={setLimit}
									setPercent={setPercent}
									setLeadData={setLeadData}
								/>
							</div>
							<div className={classNames(css.menuContainer, "menu")}>
								<div className={css.menu}>
									<div
										className={classNames(css.item, menu === 1 && css._active)}
										onClick={() => setMenu(1)}
									>
										<span>AVIASOFT</span>
									</div>
									<div
										className={classNames(css.item, menu === 3 && css._active)}
										onClick={() => setMenu(3)}
									>
										<span>{!lang ? "HOW TO PLAY" : "Kaise khelein"}</span>
									</div>
									<div
										className={classNames(css.item, menu === 2 && css._active)}
										onClick={() => setMenu(2)}
									>
										<span>{!lang ? "HELP ME" : "Meri madad karo"}</span>
									</div>
									<div
										className={classNames(css.item, menu === 4 && css._active)}
										onClick={() => setMenu(4)}
									>
										<span>{!lang ? "HISTORY" : "Itihaas"}</span>
									</div>
								</div>
							</div>
						</>
					)}
					{path === "signup" && (
						<Signup
							userData={data}
							setUserData={setData}
							setPath={setPath}
							setMenu={setMenu}
						/>
					)}
					{path === "signin" && (
						<Signin
							setData={setData}
							setPath={setPath}
							setMenu={setMenu}
						/>
					)}
					<ToastContainer
						position="top-right"
						autoClose={2000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
					/>
				</Context.Provider>
			</>
		</>
	);
};

export default App;
