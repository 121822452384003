import React, { useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import { Context } from "../../App";

const Help = ({ menu, setMenu, percent = 0, leadData }) => {
	const { language } = useContext(Context);

	return (
		<div className={classNames(css.container, menu === 2 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>
				<section className={css.help}>
					<div
						className={css.avatar}
						style={{ backgroundImage: `url("/images/ind.png")` }}
					></div>
					<div className={css.contentHelp}>
						<span className={css.username}>@{leadData.username}</span>
						<span className={css.info}>Contact the software creator</span>
						<button onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}>
							<span>MESSAGE ME</span>
						</button>
					</div>
				</section>
				<section className={css.help}>
					<div
						className={css.avatar}
						style={{ backgroundImage: `url("/images/tgk.png")` }}
					></div>
					<div className={css.contentHelp}>
						<span className={css.username}>AVIATOR SOFTCLUB</span>
						<span className={css.info}>Telegram channel </span>
						<button>
							<span>SUBSCRIBE</span>
						</button>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Help;
