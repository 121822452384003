import React, { useState, useEffect, useContext } from "react";
import css from "../../styles/Login.module.scss";
import classNames from "classnames";
import axios from "axios";
import Plane from "../Plane";
import PopupFallId from "../popups/PopupFallId";
import PopupMakeDeposit from "../popups/PopupMakeDeposit";
import VideoId from "../OtherPages/VideoId";
import { Context } from "../../App";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";

const Signin = ({ setData, setPath, setMenu }) => {
	const { language } = useContext(Context);

	const [lang, setLang] = useState(false);
	const [popup1, setPopup1] = useState(false);
	const [dataPopup1, setDataPopup1] = useState(false);
	const [popup2, setPopup2] = useState(false);

	const [showVideo, setShowVideo] = useState(false);

	const [id, setId] = useState("");

	useEffect(() => {
		const body = document.querySelector("body");
		body.style.overflow = "hidden";
	}, []);

	const handleChange = e => {
		const { name, value } = e.target;
		let newValue = value.replace(/\D/g, "");
		setId(newValue);
	};

	const handleSubmit = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/login?platform_id=${id}`,
			);

			const { data, success } = response.data;

			if (!success) {
				return console.log(new Error());
				// return errorMessage("Error, check the data is correct");
			} else {
				if (!!data.is_login) {
					setData(data);
					return setPath("");
				}

				if (!data.is_search) {
					return setPopup2(true);
				}

				if (!data.is_amount) {
          setPopup1(true);
          setData(data)
					return setDataPopup1(data);
				}

				if (!!data.is_amount && !data.is_registration) {
					setData(response.data.data);
					return setPath("signup");
				} else {
					// return errorMessage("Error, check the data is correct");
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<div
				className="uiContainer2"
				style={!!showVideo ? { zIndex: "101" } : {}}
			>
				<div
					className={css.main}
					style={{ maxHeight: "100dvh" }}
				>
					<div className={classNames(css.screen, css._firstScreen)}>
						<div className="uiBgElement" />
						<div className={classNames(css.signin)}>
							{!language && <span className={css.title}>enter your id below</span>}
							{!!language && <span className={css.title}>Apna ID neeche daalo</span>}
							<div className={css.inputContainer}>
								<label
									className={css.inputLabel}
									htmlFor="input-id"
								>
									<input
										className={classNames(css.input, !!id.length && css.inputActive)}
										id="input-id"
										type="text"
										placeholder="ID"
										value={id}
										onChange={handleChange}
									/>
								</label>
								<div className={css.inputBorder} />
							</div>
							<div className={css.actions}>
								<button
									className="uiButton"
									onClick={handleSubmit}
								>
									{!language ? "Confirm" : "Confirm karo"}
								</button>
								<button
									className={css.whereId}
									onClick={() => setShowVideo(true)}
								>
									{!language ? "where can i get id?" : "ID kahaan se mil sakta hai?"}
								</button>
								{showVideo && <VideoId setShowVideo={setShowVideo} />}
							</div>
						</div>
						<div className={css.showMore}>
							<span>
								{!language ? "more about the software" : "Software ke baare mein aur zyada batao"}
							</span>
							<div className={css.arrow} />
						</div>
					</div>
					<div
						className={classNames(css.screen, css.screenSlider)}
						style={{ paddingLeft: "0", paddingRight: "0" }}
					>
						<span className={css.titleScreen}>
							{!language ? "what does it look like?" : "Yeh kaisa dikhta hai?"}
						</span>
						<div className={css.sliderContainer}>
							<Swiper
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Pagination, Navigation]}
								navigation={true}
								loop={true}
								className="mySwiper"
							>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/1.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/11.png")` }}
										/>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/2.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/22.png")` }}
										/>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/3.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/33.png")` }}
										/>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/4.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/44.png")` }}
										/>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/5.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/55.png")` }}
										/>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div
										className={css.slide}
										style={{ backgroundImage: `url("/images/bg/6.png")` }}
									>
										<div
											className={css.slideContent}
											style={{ backgroundImage: `url("/images/slide/66.png")` }}
										/>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
					<div
						className={classNames(css.screen, css.screenSlider, css._bg)}
						style={{ padding: "0px", backgroundImage: "$background" }}
					>
						<span className={css.titleScreen}>
							{!language ? "what does our software do?" : "Hamara software kya karta hai?"}
						</span>
						<div className={css.info}>
							<div className={css.item}>
								<div className={css.num}>1</div>
								<p className={css.text}>
									{!language
										? "Predicts OODS for Aviator game"
										: "Aviator game ke liye odds predict karta hai"}
								</p>
							</div>
							<div className={css.item}>
								<div className={css.num}>2</div>
								<p className={css.text}>
									{!language
										? "Calculates your chance of winning"
										: "Aapki jeetne ki chance calculate karta hai"}
								</p>
							</div>
							<div className={css.item}>
								<div className={css.num}>3</div>
								<p className={css.text}>
									{!language
										? "Helps increase your profits"
										: "Aapke profits badhane mein madad karta hai"}
								</p>
							</div>
						</div>
					</div>
					<div
						className={classNames(css.screen, css.screenSlider)}
						style={{ paddingLeft: "0", paddingRight: "0" }}
					>
						<span className={css.titleScreen}>video reviews</span>
						<div className={css.sliderContainer}>
							{" "}
							<Swiper
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Pagination, Navigation]}
								navigation={true}
								loop={true}
								className="mySwiper"
							>
								<SwiperSlide>
									<div className={css.slide}>1</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={css.slide}>2</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={css.slide}>3</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={css.slide}>4</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={css.slide}>5</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={css.slide}>6</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
					<div
						className={classNames(css.screen, css.screenSlider)}
						style={{ paddingLeft: "0", paddingRight: "0" }}
					>
						<span className={css.titleScreen}>reviews</span>
						<div className={css.sliderContainer}>
							<Swiper
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Pagination, Navigation]}
								navigation={true}
								loop={true}
								className="mySwiper"
							>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((item, index) => {
									return (
										<SwiperSlide key={`${index}`}>
											<div
												className={css.slide}
												style={{ height: "485px" }}
											>
												<div
													className={css.slideContent}
													style={{
														backgroundImage: `url("/images/comments/${index + 1}.PNG")`,
														backgroundSize: "cover",
													}}
												/>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</div>
				</div>
			</div>
			{popup1 && (
				<PopupMakeDeposit
					data={dataPopup1}
          setShowPopup={setPopup1}
          setMenu={setMenu}
				/>
			)}
			{popup2 && <PopupFallId setShowPopup={setPopup2} />}
		</>
	);
};

export default Signin;
