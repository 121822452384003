/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import css from "../styles/Container.module.scss";
import classNames from "classnames";
import axios from "axios";
import numeral from "numeral";
import anime from "animejs";
import { io } from "socket.io-client";
import Card from "./Card";
import Coeffs from "./Coeffs";
import PreviusRound from "./PreviusRound";
import PredictionLimit from "./PredictionLimit";
import Confetti from "react-confetti";
import IncraceChance from "./IncraceChance";
import { Context } from "../App";

const Container = ({ data, setMenu, setLimit, setPercent, setLeadData }) => {
	const { language } = useContext(Context);

	const [card, setCard] = useState({ card: 1 });

	const [oldCoeffs, setOldCoeffs] = useState([]);

	const [userData, setUserData] = useState({});

	const [socket, setSocket] = useState(null);

	const [confettiSizes, setConfettiSizes] = useState({ width: 0, height: 0 });

	const [currentLimit, setCurrentLimit] = useState({});
	const [firstStart, setFirstStart] = useState(false);
	const waitingRef = useRef(0);
	const coeffsRef = useRef(null);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				getLimit();
				getStory();
				// getStoryBack();
			} else {
				// setOldCoeffs([]);
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		const socket = io(`${process.env.REACT_APP_LINK}`, {
			reconnectionAttempts: 5,
			reconnectionDelay: 5000,
			timeout: 20000,
			transports: ["websocket"],
		});

		setSocket(socket);

		socket.on("connect", () => {
			socket.emit("roundRoom");
		});

		socket.on("rounds", data => {
			try {
				setOldCoeffs(data.history);
				coeffsRef.current = data.history;
			} catch (error) {
				console.error("Event handler error:", error);
			}
		});

		socket.on("disconnect", () => {
			console.log("Socket.io зєднання закрите");
		});

		socket.on("connect_error", error => {
			setCard(7);
			console.error("Connect error:", error);
		});

		getLimit();
		getStory();
		getData();
		const body = document.querySelector("body");
		const width = body.offsetWidth;
		const height = body.offsetHeight;

		setConfettiSizes({ width, height });
	}, []);

	const getData = async () => {
		try {
			const response = await axios.get(
				`${
					process.env.REACT_APP_HOST
				}/api/AviatorSoft/Client/getData?platform_id=${+data.platform_id}`,
			);
			if (!!response.data.success) {
				setLeadData({ ...response.data.data });
				setUserData({ ...response.data.data });
				setPercent({ ...response.data.data });
				localStorage.setItem("aviatorUser", JSON.stringify(response?.data?.data));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getStory = async () => {
		const response = await fetch(`${process.env.REACT_APP_LINK}/api/odds/rounds`);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const result = await response.json();
		setOldCoeffs(result);
		coeffsRef.current = result;
	};

	// const getStoryBack = async () => {
	// 	const response = await fetch(`${process.env.REACT_APP_LINK}/api/odds/rounds`);
	// 	if (!response.ok) {
	// 		throw new Error("Network response was not ok");
	// 	}
	//   const result = await response.json();
	//   const findLastVisibleCoeff = result.findIndex(item => item.id === coeffsRef.current[0].id);
	//   console.log(findLastVisibleCoeff)
	// };

	const getCurrentRound = async () => {
		const response = await fetch(`${process.env.REACT_APP_LINK}/api/odds/current-state`);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const result = await response.json();
		changeStatus(result);
	};

	const getLimit = async () => {
		const response = await fetch(`${process.env.REACT_APP_LINK}/api/limits/${data?.lead_id}`);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const result = await response.json();

		setCurrentLimit({ ...result });
		setLimit({ ...result });
	};

	const addLimit = async () => {
		const response = await fetch(`${process.env.REACT_APP_LINK}/api/limits/${data?.lead_id}`, {
			method: "POST",
		});
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
	};

	const getState = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_LINK}/api/limits/status/${data?.lead_id}`,
		);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const result = await response.json();

		if (!!result.limit_status) {
			if (!firstStart) {
				changeStatus({ status: "error" });
				setFirstStart(true);
				connectSockets();
			} else {
				waitingRef.current = 0;
				changeStatus({ status: "error" });
			}
			addLimit();
		} else {
			setMenu(6);
		}
	};

	const connectSockets = () => {
		socket.on("round", data => {
			try {
				changeStatus(data);
			} catch (error) {
				setCard(7);
				console.error("Event handler error:", error);
			}
		});
	};

	const changeStatus = data => {
		if (waitingRef.current === 0) {
			if (data.status !== "waiting" && data.status !== "error") {
				return;
			}
		}
		if (waitingRef.current === 2) {
			setCard({ card: 1 });
			return;
		}

		switch (data.status) {
			case "waiting":
				if (card !== 2) {
					if (waitingRef.current === 0) {
						waitingRef.current = waitingRef.current + 1;
					}

					setCard({ card: 2, coeff: Number(data?.predicted) });
				}
				break;
			case "play":
				if (card !== 3) {
					setCard({ card: 3, coeff: Number(data?.predicted) });
				}
				break;
			case "success":
				if (card !== 4) {
					setCard({ card: 4, coeff: Number(data?.predicted) });
				}
				break;
			case "flew":
				if (card !== 5 && card !== 6) {
					if (data.win === 1) {
						waitingRef.current = waitingRef.current + 1;
						setCard({
							card: 5,
							coeff: Number(data?.predicted),
							coeff2: Number(data?.coefficient),
						});
					} else {
						setCard({
							card: 6,
							coeff: Number(data?.predicted),
							coeff2: Number(data?.coefficient),
						});
					}
				}
				break;
			default:
				if (card !== 7) setCard({ card: 7, coeff: Number(data?.predicted) });
				break;
		}
	};

	return (
		<>
			{card.card === 4 && (
				<Confetti
					width={confettiSizes.width}
					height={confettiSizes.height}
				/>
			)}
			<div className={css.main}>
				<header className={css.header}>
					<section className={css.history}>
						<Coeffs oldCoeffs={oldCoeffs} />
						<PreviusRound
							users={oldCoeffs[0]?.users}
							winners={oldCoeffs[0]?.winners}
						/>
					</section>
				</header>
				<Card
					card={card}
					currentLimit={currentLimit}
				/>
				<section className={css.actions}>
					<div className={classNames(css.buttons, css._card_1, card.card === 1 && css._active)}>
						<button
							className={classNames(css.b, css._1)}
							onClick={getState}
						>
							<span>
								-1
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="22"
									fill="none"
									viewBox="0 0 14 22"
								>
									<path
										fill="#fff"
										d="m6.774 14.006.134-.95H0L7.328 0 6.56 7.412l-.134.95h6.909L6.007 21.419l.767-7.412Z"
									/>
								</svg>
								{!language ? "PREDICT" : "PREDICT"}
							</span>
						</button>
						<button
							className={classNames(css.b, css._2)}
							onClick={() => setMenu(10)}
							// onClick={() =>
							// 	window.open(`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`, "_blank")
							// }
						>
							<span>AVIATOR</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_2, card.card === 2 && css._active)}>
						<button
              className={classNames(css.b, css._2, css._4)}
              onClick={() => setMenu(10)}
							// onClick={() =>
							// 	window.open(`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`, "_blank")
							// }
						>
							{!language && <span>MAKE A BET</span>}
							{!!language && <span>Bet lagao</span>}
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_3, card.card === 3 && css._active)}>
						<button
              className={classNames(css.b, css._2, css._21)}
              onClick={() => setMenu(10)}
							// onClick={() =>
							// 	window.open(`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`, "_blank")
							// }
						>
							<span>AVIATOR</span>
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_4, card.card === 4 && css._active)}>
						<button
              className={classNames(css.b, css._3, css._flicker)}
              onClick={() => setMenu(10)}
							// onClick={() =>
							// 	window.open(`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`, "_blank")
							// }
						>
							{!language && <span>CASH OUT</span>}
							{!!language && <span>Cash out karo</span>}
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_5, card.card === 5 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._3)}
							onClick={() => setMenu(5)}
						>
							{!language && <span>INCREASE CHANCE</span>}
							{!!language && <span>Chance badhao</span>}
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_6, card.card === 6 && css._active)}>
						<button
							className={classNames(css.b, css._2, css._21)}
							onClick={() => setMenu(5)}
						>
							{!language && <span>INCREASE CHANCE</span>}
							{!!language && <span>Chance badhao</span>}
						</button>
					</div>
					<div className={classNames(css.buttons, css._card_7, card.card === 7 && css._active)}>
						<button
              className={classNames(css.b, css._2, css._21)}
              onClick={() => setMenu(10)}
							// onClick={() =>
							// 	window.open(`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`, "_blank")
							// }
						>
							<span>AVIATOR</span>
						</button>
					</div>
				</section>
				<footer className={css.footer}>
					<PredictionLimit
						currentLimit={currentLimit}
						getLimit={getLimit}
						setMenu={setMenu}
					/>
					<IncraceChance
						setMenu={setMenu}
						data={userData}
					/>
				</footer>
			</div>
		</>
	);
};

export default Container;
