import React, { useState, useEffect, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import VideoDeposit from "./VideoDeposit";
import { Context } from "../../App";

const IncreaseChance = ({ menu, setMenu, leadData }) => {
	const { language } = useContext(Context);

	const [percent, setPercent] = useState(0);
	const [showVideo, setShowVideo] = useState(false);

	useEffect(() => {
		if (menu !== 5) return;
		const perc = JSON.parse(localStorage.getItem("aviatorUser")).percent;
		setPercent(perc);
	}, [menu]);

	useEffect(() => {
		const menu = document.querySelector(".menu");
		const head = document.querySelector(".head");

		if (showVideo) {
			menu.style.display = "none";
			head.style.display = "none";
		} else {
			menu.style.display = "flex";
			head.style.display = "flex";
		}

		return () => {
			menu.style.display = "flex";
			head.style.display = "flex";
		};
	}, [showVideo]);

	return (
		<div className={classNames(css.container, menu === 5 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>

				<section className={css.pgContainer}>
					<div
						className={css.progressLineContainer}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "20px",
						}}
					>
						<div
							className={css.progress}
							style={{ flexGrow: "1" }}
						>
							<div
								className={classNames(
									css.line,
									percent <= 39.99 && css._red,
									percent >= 40 && percent <= 79 && css._gold,
									percent >= 79.01 && css._green,
								)}
								style={{ width: `${percent}%` }}
							></div>
						</div>

						<div
							className={css.chanceContainer}
							style={{ width: "fit-content", justifyContent: "flex-end" }}
						>
							<div className={css.proc}>
								<span>{numeral(percent).format("0.00")}%</span>
								{!language && <span style={{ whiteSpace: "nowrap" }}>Chance of winning</span>}
								{!!language && <span style={{ whiteSpace: "nowrap" }}>Jeetne ka chance</span>}
							</div>
						</div>
					</div>
				</section>
				<section className={css.wining}>
					{!language && (
						<div className={css.title}>
							How to increase your
							<br /> chance of <span>winning</span>?
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Kaise aapka <span>jeetne</span>
							<br /> ka chance badhayein?
						</div>
					)}
					{!language && (
						<p className={css.text}>
							To increase your current chance <br /> of winning you need to make a deposit; <br />{" "}
							the higher it is, the greater the chance <br /> will increase.
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Aapka jeetne ka current chance <br /> badhane ke liye aapko deposit karna hoga; <br />{" "}
							jitna zyada deposit hoga, utna zyada <br /> chance badhega.
						</p>
					)}
				</section>
				<section
					className={css.buttons}
					style={{ marginTop: "16px" }}
				>
					<button
						className="uiButton"
						style={{ padding: "0px" }}
						onClick={() =>
							window.open(`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`, "_blank")
						}
					>
						{!language ? "MAKE DEPOSIT" : "Deposit karo"}
					</button>
					<button
						className="uiButton uiButton_2"
						onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}
					>
						{!language ? "HELP" : "Madad"}
					</button>
				</section>
				<section className={css.blockVideo}>
					<button
						className={css.play}
						onClick={() => setShowVideo(true)}
					/>
					{!!showVideo && <VideoDeposit setShowVideo={setShowVideo} />}
				</section>
			</div>
		</div>
	);
};

export default IncreaseChance;
